@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sura:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}


body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-family: "Sura", serif;
  font-weight: 400;
  font-style: normal;
  color: #333333;
}

.active {
  color: #6A2314;
  font-weight: 700;
  /* Set the desired color */
  text-decoration: underline;
  /* Underline the link */
}




.custom-otp-field {
  @apply mx-2 h-10 !w-10 border rounded-md border-gray-400;
}

.custom-otp-field:first-child {
  @apply mr-1 ml-0;
}

@media (max-width: 768px) {
  .custom-otp-field {
    width: 32px !important;
    height: 32px !important;
    margin: 0 5px;
  }
}