.carousel-container-product {
    position: relative;
    padding-bottom: 40px;
    height: 550px;
    width: 100%;
    /* Ensure there's space for the arrows */
}

.carousel-container-product-mobile {
    position: relative;
    padding-bottom: 40px;
    height: auto;
    /* Ensure there's space for the arrows */
}

.custom-arrow-product {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #663B33;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #663B33 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.custom-arrow-product-desktop {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #663B33;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #663B33 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.custom-arrow:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.custom-dot-list-product {
    display: flex !important;
    justify-content: center;
    margin-top: 5px;
    bottom: 30px !important;
    /* Adjust as necessary */
}

.custom-dot button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    background: #c4c4c4;
    margin: 0 5px;
    cursor: pointer;
    /* bottom: 16px !important; */
}

.custom-dot.active button {
    background: #000;
}