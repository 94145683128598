.carousel-container-productview {
    position: relative;
    padding-bottom: 40px;
    height: 600px;
    /* Ensure there's space for the arrows */
}

.custom-arrow-productview {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #663B33;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #663B33 !important;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.custom-arrow:hover {
    background-color: #663B33;
}

.custom-dot-list-productview {
    display: flex !important;
    justify-content: center;
    margin-top: 5px;
    bottom: 65px !important;
    /* Adjust as necessary */
}

.custom-dot button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    /* border: none !important; */
    background: #D9D9D9 !important;
    border: 1px solid #663B33 !important;
    margin: 0 5px;
    cursor: pointer;
    /* bottom: 16px !important; */
}

.custom-dot.active button {
    background: #663B33;
}

.react-multi-carousel-dot--active button {
    background-color: #663B33 !important;
}

.custom-btn {
    background-color: #663B33;
    /* Custom blue color */
    color: white;
    /* Text color */
    border-radius: 0.375rem;
    /* Rounded corners */
}

/* .custom-btn:hover {
    background-color: #663B33;
} */