/* Add this CSS in your global stylesheet or component's CSS file */
.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.sample {
    opacity: 1;
    transition: opacity 0.5s ease;
    /* Adjust timing and easing function as needed */
}

.fade-out {
    opacity: 0;
}