.carousel-container {
    position: relative;
    padding-bottom: 40px;
    height: 650px;
    /* Ensure there's space for the arrows */
}

.custom-arrow {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #663B33;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #663B33 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.custom-arrow:hover {
    background-color: #663B33;
}

.custom-dot-list {
    display: flex !important;
    justify-content: center;
    margin-top: 5px;
    bottom: 30px !important;
    /* Adjust as necessary */
}

.custom-dot button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    /* border: none !important; */
    background: #D9D9D9 !important;
    border: 1px solid #663B33 !important;
    margin: 0 5px;
    cursor: pointer;
    /* bottom: 16px !important; */
}

.custom-dot.active button {
    background: #663B33;
}

.react-multi-carousel-dot--active button {
    background-color: #663B33 !important;
}